import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import styles from './EmployeeHighlightPage.module.scss'
import { richTextOptions } from '@components/Markdown'
import Picture from '@components/Picture'
import Text from '@components/Text'
import GradientText from '@components/GradientText'
import { TransitionState } from 'gatsby-plugin-transition-link'
import {
  gradientColor1,
  gradientColor2,
  gradientColor3,
  gradientStop1,
  gradientStop2,
  gradientStop3,
} from '@styles/variables.module.scss'

import Layout from '@components/Layout'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { renderToString } from 'react-dom/server'

const cx = classnames.bind(styles)

const EmployeeHighlightPage = ({ pageContext: { name, cardTitle, jobTitle, video, fullPageImage, moreInfo } }) => {
  const [, setOpacityStyle] = useState()
  const handleOpacityChange = style => {
    setOpacityStyle(style)
  }

  const videoUrl = video ? video.file.url : ''
  const info = moreInfo ? documentToReactComponents(moreInfo.json, richTextOptions) : ''
  const formattedInfo = info ? renderToString(info) : ''

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout className={transitionStatus} onOpacityChange={handleOpacityChange} seo={'test'} smoothScroll={true}>
            <div className={cx('page-con')}>
              <div className={cx('highlight-con')}>
                <Text tag="p" type="h3" className={cx('name')}>
                  {name}
                </Text>

                <Text tag="p" type="h3" className={cx('job-title')}>
                  {jobTitle}
                </Text>

                <div className={cx('divider-line')}></div>
                {fullPageImage && (
                  <div className={cx('top-image-con', video ? 'add-margin-bottom' : '')}>
                    <Picture className={cx('top-image')} src={fullPageImage.file.url} alt={fullPageImage.description} />
                  </div>
                )}
                {video && (
                  <div className={cx('video-con')}>
                    <video className={cx('video')} controls>
                      Your browser does not support the &lt;video&gt; tag.
                      <source src={videoUrl} />
                    </video>
                  </div>
                )}

                <GradientText
                  tag="h1"
                  className={cx('highlight-title')}
                  type="h1"
                  colors={[gradientColor1, gradientColor2, gradientColor3]}
                  colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                >
                  {cardTitle}
                </GradientText>

                {formattedInfo && (
                  <Text tag="p" type="b2" className={cx('exp-snippet')}>
                    {formattedInfo && (
                      <div className={cx('modal-content')} dangerouslySetInnerHTML={{ __html: formattedInfo }} />
                    )}
                  </Text>
                )}
                <div className={cx('back-button-con')}>
                  <GradientText
                    tag="h1"
                    className={cx('back-button')}
                    type="h1"
                    colors={[gradientColor1, gradientColor2, gradientColor3]}
                    colorStops={[gradientStop1, gradientStop2, gradientStop3]}
                    onClick={() => {
                      const tab = 'M'
                      const query = `?t=${JSON.stringify(tab)}`

                      window.location.href = '/careers' + query
                    }}
                  >
                    {'Back'}
                  </GradientText>
                </div>
              </div>
            </div>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

EmployeeHighlightPage.propTypes = {
  data: PropTypes.object,
}

export default EmployeeHighlightPage
